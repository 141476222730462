import { ORG_TYPES, ROLES } from "../constants/roles";

type IPermissions = {
  [key: string]: {
    roles: ROLES[];
    orgTypes: ORG_TYPES[];
  };
};

// eslint-disable-next-line no-shadow
enum PERMISSIONS_LIST {
  seeMSPPages = "seeMSPPages",
  seeUsers = "seeUsers",
  seeSettings = "seeSettings",
  seeInspectors = "seeInspectors"
}

export const permissions: IPermissions = {
  // See page permissions
  seeMSPPages: {
    roles: [ROLES.Admin],
    orgTypes: [ORG_TYPES.provider]
  },
  seeUsers: {
    roles: [ROLES.Admin],
    orgTypes: [ORG_TYPES.client, ORG_TYPES.provider]
  },
  seeSettings: {
    roles: [ROLES.Admin],
    orgTypes: [ORG_TYPES.client, ORG_TYPES.provider]
  },
  seeInspectors: {
    roles: [ROLES.Admin],
    orgTypes: [ORG_TYPES.client, ORG_TYPES.provider]
  }
};

export default PERMISSIONS_LIST;

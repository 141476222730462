/* eslint-disable react/no-unstable-nested-components */
import React, { useContext } from "react";
import "./settings.scss";
import { SETTINGS } from "../constants/images";
import Header from "../designSystem/header/header";
import Icon from "../designSystem/icon/icon";
import TenantInformation from "../profile/components/tenantInformation/tenantInformation";
import ScansFrequency from "./components/scan-frequency/scans-frequency";
import useSettings from "./settings.view.model";
import { SCAN_FREQUENCIES_CONSTANTS } from "./components/scan-frequency/scans-frequency.constants";
import Button from "../designSystem/button/button";
import SkeletonLoader from "../designSystem/skeleton/skeleton-loader";
import SKELETON_VARIANT from "../designSystem/skeleton/skeleton.constants";
import OrganizationsContext from "../contexts/organizations.context";

export default function Settings() {
  const {
    scanFrequenciesOptions,
    feature_scan_Frequency,
    loading,
    showChangeFrequencyModal,
    scanFrequency,
    nextScan,
    lastScan,
    tenantId,
    orgName
  } = useSettings();

  const {
    featureFlags: { feature_request_scan }
  } = useContext(OrganizationsContext);

  const clickRequestScan = () => {};

  return (
    <>
      <Header
        icon={<Icon image={SETTINGS} alt="Settings" />}
        title="Settings"
      />
      <TenantInformation
        tenantId={tenantId}
        orgName={orgName}
        additionalClassName="settings__tenant"
        isLoading={loading}
      />
      {feature_scan_Frequency && (
        <>
          <ScansFrequency
            scanFrequencies={scanFrequenciesOptions}
            frequencyValue={scanFrequency}
            nextScanValue={nextScan}
            lastScanValue={lastScan}
            onSelectedFrequency={(val) => {
              showChangeFrequencyModal(val);
            }}
            isLoading={loading}
          />
          {feature_request_scan && (
            <section className="settings__scan-request">
              <h4>{SCAN_FREQUENCIES_CONSTANTS["actions"]}</h4>
              <div className="settings__scan-request-button-holder">
                <label>{SCAN_FREQUENCIES_CONSTANTS["request a scan"]}</label>

                <SkeletonLoader
                  loadingVar={loading}
                  variant={SKELETON_VARIANT.select}
                >
                  <Button onClick={clickRequestScan}>
                    {SCAN_FREQUENCIES_CONSTANTS["request"]}
                  </Button>
                </SkeletonLoader>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
}

import { apiResponseFormatter } from "../utils/object.utils";
import { apiRequest, useOptions } from "./api";
import API_METHODS from "./apiMethods";

export default function useScanFrequencyAPI() {
  const { getOptions, setBody, setMethod } = useOptions();
  const { setEndpoint, run } = apiRequest();
  const apiUrl: string = process.env.REACT_APP_API_SCAN_FREQUENCY!;
  const apiPrefix = `${apiUrl}/schedules`;

  const getScanFrequencyByOrgId = async (orgId: string) => {
    setEndpoint(`${apiPrefix}/${orgId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const organizations = await run(options);
    return apiResponseFormatter(organizations);
  };

  const updateScanFrequency = async (orgId: string, frequency: string) => {
    setEndpoint(`${apiPrefix}/set`);
    setMethod(API_METHODS.POST);
    setBody({
      frequency,
      orgId
    });
    const options = getOptions();
    const organizations = await run(options);
    return apiResponseFormatter(organizations);
  };

  return {
    getScanFrequencyByOrgId,
    updateScanFrequency
  };
}

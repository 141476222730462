import React from "react";
import { SCAN_FREQUENCIES_CONSTANTS } from "./scans-frequency.constants";
import "./scan-frequency.scss";

export default function ChangeScanFrequencyModalInfo({
  frequencyOption,
  orgName,
  currentFrecuency
}: {
  frequencyOption: string;
  orgName: string;
  currentFrecuency?: string;
}) {
  return (
    <div className="scan-frequency__modal-info">
      <p>
        {`${SCAN_FREQUENCIES_CONSTANTS["organization"]}: `}
        <strong>{orgName}</strong>
      </p>
      <p>
        {`${SCAN_FREQUENCIES_CONSTANTS["current scan schedule is"]}: `}
        <strong>
          {currentFrecuency
            ? SCAN_FREQUENCIES_CONSTANTS[
                currentFrecuency as keyof typeof SCAN_FREQUENCIES_CONSTANTS
              ]
            : SCAN_FREQUENCIES_CONSTANTS["custom"]}
        </strong>
      </p>
      <p>
        {`${SCAN_FREQUENCIES_CONSTANTS["new scan schedule will be"]}: `}
        <strong>
          {
            SCAN_FREQUENCIES_CONSTANTS[
              frequencyOption as keyof typeof SCAN_FREQUENCIES_CONSTANTS
            ]
          }
        </strong>
      </p>
    </div>
  );
}

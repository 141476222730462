import { useAppSelector } from "../redux/hooks";
import API_METHODS from "./apiMethods";

type Options = {
  method: string;
  headers: Headers;
  body?: string;
};

// eslint-disable-next-line no-shadow
enum STATUS_CODES {
  OK = 200,
  NO_CONTENT = 204,
  CREATED = 201
}

// function in charge of getting service friendly message as string
function getFriendlyMessageFromApi(responseText = "{}") {
  if (responseText.startsWith("{")) {
    const { error: { friendlyMessage = "" } = {} } = JSON.parse(responseText);
    return friendlyMessage;
  }
  return "";
}

export function useOptions() {
  const token = useAppSelector((state: any) => state.session.accessToken);

  let method: API_METHODS | "" = "";
  let body = "";

  const buildHeaders = (): Headers => {
    const headers = new Headers();
    token.length && headers.append("Authorization", `Bearer ${token}`);
    return headers;
  };

  const setMethod = (value: API_METHODS) => {
    method = value;
  };
  const setBody = (value: any) => {
    body = JSON.stringify(value);
  };

  const getOptions = (): Options => ({
    method,
    headers: buildHeaders(),
    ...(body.length &&
    ![API_METHODS.GET, API_METHODS.DELETE].includes(method as API_METHODS)
      ? { body }
      : {})
  });

  return {
    setMethod,
    setBody,
    getOptions
  };
}

export function apiRequest() {
  let endpoint = "";

  const setEndpoint = (value: string) => {
    endpoint = value;
  };

  const fetchFromEndpoint = async (options: Options) => {
    try {
      const response = await fetch(endpoint!, options as RequestInit);
      if (!response.ok) {
        const { bodyUsed } = response;
        const error = bodyUsed
          ? `${await response.text()}. Code: ${response.status}`
          : `Code: ${response.status}`;

        throw new Error(error, {
          cause: {
            status: response.status,
            friendlyMessage: getFriendlyMessageFromApi(await response.text())
          }
        });
      }
      return response;
    } catch (error: any) {
      if (error.cause) {
        throw new Error(error.message, { cause: error.cause });
      } else {
        throw new Error(error.message);
      }
    }
  };

  const run = async (options: Options) => {
    const response = await fetchFromEndpoint(options);
    // TODO: add more okish responses as needed
    const { status } = response;

    if (status === STATUS_CODES.OK || status === STATUS_CODES.CREATED) {
      // eslint-disable-next-line no-return-await
      return await response.json();
    }
    if (status === STATUS_CODES.NO_CONTENT) {
      return null;
    }
    throw Error(`Unknown code ${response.status} on successful request`);
  };

  return {
    setEndpoint,
    run
  };
}

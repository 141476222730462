import React, { createContext } from "react";

export type IfeatureFlagContext = {
  feature_frameworks_mapping: boolean;
  feature_scan_Frequency: boolean;
  feature_request_scan: boolean;
};

const featureFlagsContext = createContext<IfeatureFlagContext>(
  {} as IfeatureFlagContext
);

function convertFeatureFlagStringToBoolean(featureFlagString = ""): boolean {
  return featureFlagString?.toLocaleLowerCase() === "true" || false;
}

export const FEATURE_FLAGS = Object.freeze({
  feature_frameworks_mapping: convertFeatureFlagStringToBoolean(
    process.env.REACT_APP_FEATURE_FLAG_FRAMEWORK_MAPPINGS_ENABLED
  ),
  feature_scan_Frequency: convertFeatureFlagStringToBoolean(
    process.env.REACT_APP_FEATURE_FLAG_SCAN_FREQUENCY_ENABLED
  ),
  feature_request_scan: false
});

export function FeatureFlagsProvider({ children }: any) {
  return (
    <featureFlagsContext.Provider value={FEATURE_FLAGS}>
      {children}
    </featureFlagsContext.Provider>
  );
}

export default featureFlagsContext;

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useScanFrequencyAPI from "../api/useScanFrequencyAPI";
import { FREQUENCY_ENUM } from "./components/scan-frequency/scans-frequency.types";
import { ERROR } from "../constants/images";
import SnackbarContext from "../contexts/snackbar.context";
import { MESSAGE } from "../designSystem/snackbar/snackbar.types";
import { useAppSelector } from "../redux/hooks";
import OrganizationsContext from "../contexts/organizations.context";
import formatDate, { dateFormats } from "../utils/formatDate";
import ModalContext from "../contexts/modal.context";
import { IFunction } from "../constants/common.types";

export type iFrequencyformvalues = {
  frequency: FREQUENCY_ENUM;
};

export type IFrequencyRequestParams = {
  frequency: FREQUENCY_ENUM;
};

export default function useSettingsModel() {
  const { getScanFrequencyByOrgId, updateScanFrequency } =
    useScanFrequencyAPI();
  const { organizationName, organizationId } = useAppSelector(
    (state: any) => state.session
  );
  const { closeModal, setAsyncLoading } = useContext(ModalContext);

  const { organizationsList, loading } = useContext(OrganizationsContext);
  const { orgId } = useParams();

  const [scanFrequency, setScanFrequency] = useState("");
  const [lastScan, setLastScan] = useState("");
  const [nextScan, setNextScan] = useState("");
  const [isLoadingFrequency, setIsLoadingFrequency] = useState(true);

  const orgName = useMemo(
    () => (orgId ? organizationsList[orgId!]?.name : organizationName),
    [orgId, organizationName, organizationsList]
  );

  const tenantId = useMemo(
    () => orgId && organizationsList[orgId]?.externalProvider?.id,
    [orgId, organizationsList]
  );

  const currentOrganizationId = useMemo(
    () => orgId || organizationId,
    [orgId, organizationId]
  );

  const { showSnackbar } = useContext(SnackbarContext);

  const updateValues = useCallback(
    ({
      frequency,
      nextScanDate,
      lastScanDate
    }: {
      frequency: string;
      nextScanDate: string;
      lastScanDate: string;
    }) => {
      setScanFrequency(frequency || "");

      setNextScan(
        nextScanDate
          ? formatDate(new Date(nextScanDate).valueOf(), dateFormats.iso)
          : ""
      );
      setLastScan(
        lastScanDate
          ? formatDate(new Date(lastScanDate).valueOf(), dateFormats.iso)
          : ""
      );
    },
    []
  );

  const getScanFrequency = useCallback(
    async (organizationIdParam: string) => {
      setIsLoadingFrequency(true);
      try {
        const frequencyData = await getScanFrequencyByOrgId(
          organizationIdParam
        );
        updateValues(frequencyData);
        setIsLoadingFrequency(false);
      } catch (error: any) {
        setIsLoadingFrequency(false);
        console.error(
          `Error getting organization data of ${organizationIdParam}. ${error}. Cause ${error.cause}`
        );
        showSnackbar({
          text: `Error retrieving organization data`,
          type: MESSAGE.error,
          icon: ERROR
        });
      }
    },
    [getScanFrequencyByOrgId, showSnackbar, updateValues]
  );

  const onUpdateScanFrequency = useCallback(
    async (newFrequency: string, onSuccess?: IFunction) => {
      try {
        setAsyncLoading(true);
        setIsLoadingFrequency(true);
        const frequencyResponse = await updateScanFrequency(
          currentOrganizationId,
          newFrequency
        );
        updateValues(frequencyResponse);
        setAsyncLoading(false);
        setIsLoadingFrequency(false);
        onSuccess && onSuccess();
        closeModal();
      } catch (error: any) {
        setAsyncLoading(false);
        console.error(
          `Error getting organization data of ${currentOrganizationId}. ${error}. Cause ${error.cause}`
        );
        showSnackbar({
          text: `Error retrieving organization data`,
          type: MESSAGE.error,
          icon: ERROR
        });
      }
    },
    [
      closeModal,
      currentOrganizationId,
      setAsyncLoading,
      showSnackbar,
      updateScanFrequency,
      updateValues
    ]
  );

  useEffect(() => {
    currentOrganizationId && getScanFrequency(currentOrganizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganizationId]);

  return {
    scanFrequency,
    loading: isLoadingFrequency || loading,
    lastScan,
    nextScan,
    organizationName,
    orgName,
    tenantId,
    currentOrganizationId,
    onUpdateScanFrequency
  };
}

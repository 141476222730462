// eslint-disable-next-line no-shadow
export enum FREQUENCY_ENUM {
  WEEKLY = "weekly",
  TWICE_A_MONTH = "twiceMonthly",
  MONTHLY = "monthly"
}
export type IscanFrequencies = {
  label: string;
  key: FREQUENCY_ENUM;
  selected?: boolean;
}[];
